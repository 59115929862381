<template>
  <div
    :class="[
      'template-filter-question-wrapper',
      'ps-2',
      'pe-2',
      { 'template-filter-answered-wrapper': isQuestionAlreadyAnswered },
    ]"
    @mouseenter="isMouseOnQuestion = true"
    @mouseleave="isMouseOnQuestion = false"
  >
    <div class="question-wrapper">
      <QuestionMarkFullIcon :fill="isQuestionAlreadyAnswered ? '#9295A5' : '#306FEB'" :size="20" />
      <p v-if="!isQuestionAlreadyAnswered">
        {{ $t('document.documentsOverview.classificationTasks.templateFiltering.question') }}
      </p>
      <p :class="['field-text', { 'field-text-answered': isQuestionAlreadyAnswered }]">
        {{ paramPresentedField }}
      </p>
    </div>
    <div v-if="!isQuestionAlreadyAnswered" class="question-wrapper">
      <Button class="btn-wrapper" type="secondary" @click="handleQuestionAnswer(true)">
        {{ $t('document.documentsOverview.classificationTasks.templateFiltering.yes') }}
      </Button>
      <Button class="btn-wrapper" type="secondary" @click="handleQuestionAnswer(false)">
        {{ $t('document.documentsOverview.classificationTasks.templateFiltering.no') }}
      </Button>
    </div>
    <div v-else>
      <Button v-if="!isMouseOnQuestion" class="ms-2" type="icon" :disabled="true">
        {{
          answerValue
            ? $t('document.documentsOverview.classificationTasks.templateFiltering.yes')
            : $t('document.documentsOverview.classificationTasks.templateFiltering.no')
        }}
      </Button>
      <Button v-else class="ms-2" type="icon" @click="handleAnswerRemove">
        <CloseIcon :size="16" />
      </Button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { isNil } from 'ramda';
import { Button } from '@/modules/core';
import { QuestionMarkFullIcon, CloseIcon } from '@/assets/icons';
export default {
  components: {
    Button,
    QuestionMarkFullIcon,
    CloseIcon,
  },
  props: {
    paramField: {
      type: String,
      required: true,
    },
    paramPresentedField: {
      type: String,
      required: true,
    },
    answerValue: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const isMouseOnQuestion = ref(false);
    const isQuestionAlreadyAnswered = computed(() => {
      return !isNil(props.answerValue);
    });
    const handleQuestionAnswer = (answerValue) => {
      emit('question-answer', {
        paramField: props.paramField,
        paramPresentedField: props.paramPresentedField,
        answerValue,
      });
    };
    const handleAnswerRemove = () => {
      emit('answer-remove', {
        paramField: props.paramField,
        paramPresentedField: props.paramPresentedField,
      });
    };
    return { isQuestionAlreadyAnswered, isMouseOnQuestion, handleQuestionAnswer, handleAnswerRemove };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
.template-filter-question-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e3e4e6;
  height: 56px;
  width: 388px;
}
.template-filter-answered-wrapper {
  width: 240px;
}
.question-wrapper {
  display: flex;
  gap: 4px;
}
.btn-wrapper {
  margin: 0 !important;
}
.field-text {
  font-size: 14px;
  font-weight: 500;
}
.field-text-answered {
  color: #9295a5;
}
</style>
