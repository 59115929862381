<template>
  <div>
    <div>
      <div class="w-100 d-flex justify-content-between">
        <div class="placeholder bg-secondary template-name"></div>
      </div>
      <div class="w-100 mt-2">
        <div class="placeholder bg-secondary file-viewer"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

// 1st row
.template-name {
  width: 200px;
  height: 20px;
}

// 2rd row
.file-viewer {
  width: 100%;
  height: 80vh;
}
</style>
