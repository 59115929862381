<template>
  <div>
    <div>
      <div class="mt-6">
        <div class="placeholder bg-secondary templates-panel"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
@import '@/modules/document/components/document-global.scss';

.templates-panel {
  width: 100%;
  height: calc($content-height - 130px);
}
</style>
