<template>
  <div class="wrapper">
    <div v-if="isLoading">
      <ClassificationTaskTemplatesPanelSkeleton />
    </div>
    <div v-else>
      <div class="mb-4">
        <strong class="title">
          {{ $t('document.documentsOverview.classificationTasks.templates.title') }}
        </strong>
      </div>
      <div v-if="!isSupplierAndDocTypeSelected">
        <div class="waiting-for-supplier-and-doc-type">
          {{ $t('document.documentsOverview.classificationTasks.templates.waitingForSupplierAndDocType') }}
        </div>
      </div>
      <div v-else class="row">
        <div v-for="template in templatesCard" :key="template.id" class="mb-4">
          <TemplateCard
            :general-tags="template.generalTags"
            :item-tags="template.itemsTags"
            :reference-tags="template.referenceTags"
            :previewed-path="template.previewedPath"
            :checked="template.checked"
            :template-id="template.templateId"
            :template-name="template.templateName"
            @selected="onChangeTemplate"
          />
        </div>
        <div class="mb-2">
          <NonTemplateCard
            :checked="!selectedTemplate"
            :has-locale-error-flag="hasLocaleErrorFlag"
            @selected="onChangeTemplate"
            @locale-changed="onLocaleChanged"
            @is-manual-changed="onIsManualChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';
import { omit } from 'ramda';

import ClassificationTaskTemplatesPanelSkeleton from './ClassificationTaskTemplatesPanelSkeleton';
import NonTemplateCard from './nonTemplateCard.vue';
import TemplateCard from './templateCard.vue';

export default {
  components: {
    ClassificationTaskTemplatesPanelSkeleton,
    NonTemplateCard,
    TemplateCard,
  },
  props: {
    isSupplierAndDocTypeSelected: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
    templates: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedTemplate: {
      type: String,
      required: false,
      default: null,
    },
    hasLocaleErrorFlag: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['on-change-template', 'locale-changed', 'is-manual-changed'],
  setup(props, { emit }) {
    const { $i18n } = getCurrentInstance().proxy;
    const templatesCard = computed(() => {
      const templates = props.templates || [];

      const generalTagsCount = getTagsCount(templates, 'general');
      const itemsTagsCount = getTagsCount(templates, 'items');
      const referenceTagsCount = getTagsCount(templates, 'references');

      const templatesCard = [];
      for (const template of templates) {
        const generalTags = getGeneralTagsFromTemplate(template, generalTagsCount, templates.length).map((item) => ({
          ...item,
          name: $i18n.t(`document.exports.schema.fields.${item.name}`),
        }));

        const itemsTags = getItemsTagsFromTemplate(template, itemsTagsCount, templates.length).map((item) => ({
          ...item,
          name: $i18n.t(`document.exports.schema.itemsFields.${item.name}`),
        }));

        const referenceTags = getReferenceTagsFromTemplate(template, referenceTagsCount, templates.length).map(
          (item) => ({
            ...item,
            name: $i18n.t(`document.exports.schema.referencesFields.${item.name}`),
          })
        );

        const previewedPath = template?.document?.filePathUrl;
        const checked = props.selectedTemplate === template.id;
        const templateId = template.id;
        const templateName = template.name;
        templatesCard.push({
          referenceTags,
          generalTags,
          itemsTags,
          previewedPath,
          checked,
          templateId,
          templateName,
        });
      }

      return templatesCard;

      /**
       * Return map of tags to the count of time that they appear in the structureParams
       * @param {*} templates
       * @param {'general'|'items'|'references'} type
       * @returns  [{ [tagName]: count }]
       * @example [{taxAmount: 1}, {taxRate: 3} ]
       */
      function getTagsCount(templates, type) {
        return templates.reduce((acc, template) => {
          const { structureParams = {} } = template;

          const typeStructureParams = (type === 'general' ? structureParams : structureParams[type]) || {};

          for (const [tag, isExists] of Object.entries(typeStructureParams)) {
            if (isExists) {
              acc[tag] = (acc[tag] || 0) + 1;
            }
          }
          return acc;
        }, {});
      }

      function isUnique(tagsCount, templatesCount) {
        return templatesCount <= 1 ? false : tagsCount === 1;
      }

      function getGeneralTagsFromTemplate(template, tagsCount, templatesCount) {
        return Object.entries(omit(['__typename', 'references', 'items'], template.structureParams))
          .filter(([, isExists]) => isExists)
          .map(([key]) => ({ name: key, highlighted: isUnique(tagsCount[key], templatesCount) }));
      }

      function getItemsTagsFromTemplate(template, tagsCount, templatesCount) {
        return Object.entries(omit(['__typename'], template.structureParams?.items) || {})
          .filter(([, isExists]) => isExists)
          .map(([key]) => ({ name: key, highlighted: isUnique(tagsCount[key], templatesCount) }));
      }

      function getReferenceTagsFromTemplate(template, tagsCount, templatesCount) {
        return Object.entries(omit(['__typename'], template.structureParams?.references) || {})
          .filter(([, isExists]) => isExists)
          .map(([key]) => ({ name: key, highlighted: isUnique(tagsCount[key], templatesCount) }));
      }
    });

    const onChangeTemplate = ({ templateId = null } = {}) => {
      emit('on-change-template', templateId);
    };

    const onLocaleChanged = (locale) => {
      emit('locale-changed', locale);
    };

    const onIsManualChanged = (isManual) => {
      emit('is-manual-changed', isManual);
    };

    return {
      templatesCard,
      onChangeTemplate,
      onLocaleChanged,
      onIsManualChanged,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.wrapper {
  width: 100%;
}
.waiting-for-supplier-and-doc-type {
  background-color: #f3f3f4;
  color: $typography-secondary;
  width: 100%;
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-medium;
}
</style>
